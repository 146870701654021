import React from "react"
import LayoutStatic from '../../components/LayoutStatic'
import { useIntl, Link } from 'gatsby-plugin-intl'

function Thankyou(props) {
    const intl = useIntl()
    return (
        <LayoutStatic {...props} title="Thank You">
            <h2>Thank You</h2>
            <article>
                <p style={{ textAlign: "center"}}>{intl.formatMessage({ id: 'support.thankyou'})}</p>
            </article>
            <p className="bottomLink"><Link to="/">{intl.formatMessage({ id: 'support.backToTop' })}</Link></p>
        </LayoutStatic>
    )
}
export default Thankyou
